import { Theme } from "@mui/material/styles";
import { SystemStyleObject } from "@mui/system";

const customDropdownStyles = {
  manager: (): SystemStyleObject<Theme> => ({
    "& > div > button:first-of-type > span:first-of-type, & > div > a:first-of-type > span:first-of-type":
      {
        width: "100%",
      },
  }),
  innerManager: (): SystemStyleObject<Theme> => ({
    display: "block",
    "& > div > button,& > div > a": {
      margin: "0px !important",
      color: "inherit !important",
      padding: "10px 20px !important",
      "& > span:first-of-type": {
        width: "100%",
        justifyContent: "flex-start",
      },
    },
  }),
  target: (): SystemStyleObject<Theme> => ({
    "& > button:first-of-type > span:first-of-type, & > a:first-of-type > span:first-of-type":
      {
        display: "inline-block",
      },
    "& $caret": {
      marginLeft: 0.5,
    },
  }),
  dropdown: (theme: Theme): SystemStyleObject<Theme> => ({
    border: "0",
    top: "100%",
    zIndex: 1000,
    minWidth: "60px",
    padding: "5px 0",
    margin: "2px 0 0",
    textAlign: "left",
    listStyle: "none",
    backgroundColor: theme.palette.background.paper,
    backgroundClip: "padding-box",
  }),
  menuList: (theme: Theme): SystemStyleObject<Theme> => ({
    padding: 0,
    backgroundColor: theme.palette.background.paper,
  }),
  menuHorizontal: (): SystemStyleObject<Theme> => ({
    display: "flex",
  }),
  dropdownItem: (theme: Theme): SystemStyleObject<Theme> => ({
    fontSize: "13px",
    padding: "10px 20px",
    margin: 0,
    position: "relative",
    transition: "all 150ms linear",
    display: "block",
    clear: "both",
    fontWeight: 400,
    height: "100%",
    color: theme.palette.text.primary,
    whiteSpace: "nowrap",
    minHeight: "unset",
    "&:hover": {
      backgroundColor: theme.palette.secondary.dark,
      cursor: "auto",
    },
  }),
  dropdownItemRTL: (): SystemStyleObject<Theme> => ({
    textAlign: "right",
  }),
  caret: (): SystemStyleObject<Theme> => ({
    transition: "all 150ms ease-in",
    display: "inline-block",
    width: "0",
    height: "0",
    verticalAlign: "middle",
    borderTop: "4px solid",
    borderRight: "4px solid transparent",
    borderLeft: "4px solid transparent",
    marginLeft: 0.5,
  }),
  caretActive: (): SystemStyleObject<Theme> => ({
    transform: "rotate(180deg)",
  }),
  caretDropup: (): SystemStyleObject<Theme> => ({
    transform: "rotate(180deg)",
  }),
  caretRTL: (): SystemStyleObject<Theme> => ({
    marginRight: "4px",
  }),
  dropdownHeader: (theme: Theme): SystemStyleObject<Theme> => ({
    display: "block",
    padding: "0.1875rem 1.25rem",
    fontSize: "0.75rem",
    lineHeight: "1.428571",
    color: theme.palette.text.primary,
    whiteSpace: "nowrap",
    fontWeight: "inherit",
    marginTop: "10px",
    minHeight: "24px",
    "&:hover,&:focus": {
      backgroundColor: "transparent",
      cursor: "auto",
    },
  }),
  noLiPadding: (): SystemStyleObject<Theme> => ({
    padding: 0,
  }),
  title: (): SystemStyleObject<Theme> => ({
    margin: 0,
    textTransform: "uppercase",
  }),
  alignCenter: (): SystemStyleObject<Theme> => ({
    display: "flex",
    alignItems: "center",
  }),
  marginTop: (): SystemStyleObject<Theme> => ({
    marginTop: 4,
  }),
  boxShadowStrong: (): SystemStyleObject<Theme> => ({
    boxShadow: "rgb(0 0 0 / 8%) 0px 4px 15px",
  }),
};

export default customDropdownStyles;
