import { FC, isValidElement, ReactElement, ReactNode, useState } from "react";

import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Paper from "@mui/material/Paper";
import Grow from "@mui/material/Grow";
import Divider from "@mui/material/Divider";
import Popper from "@mui/material/Popper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ButtonProps } from "@mui/material/Button";

import Button from "components/CustomButton";

import styles from "./styles";

type CustomDropdownProps = {
  title?: string;
  buttonText?: ReactElement;
  buttonIcon?: ReactElement;
  dropdownList?: ReactElement[];
  buttonProps?: ButtonProps;
  dropUp?: boolean;
  dropdownHeader?: ReactNode;
  rtlActive?: boolean;
  caret?: boolean;
  dropPlacement:
    | "bottom"
    | "top"
    | "right"
    | "left"
    | "bottom-start"
    | "bottom-end"
    | "top-start"
    | "top-end"
    | "right-start"
    | "right-end"
    | "left-start"
    | "left-end";
  noLiPadding?: boolean;
  innerDropDown?: boolean;
  children?: ReactElement;
  onClick?: (ReactElement) => void;
  onHoverAction?: boolean;
  withDivider?: boolean;
  extraMarginTop?: boolean;
  boxShadowStrong?: boolean;
  keepMounted?: boolean;
  key?: number;
  direction?: "horizontal" | "vertical";
};

const CustomDropdown: FC<CustomDropdownProps> = (props) => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const handleClick = (event) => {
    if (anchorEl && anchorEl.contains(event.target)) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };
  const handleHoverOn = (event) => {
    if (onHoverAction) {
      setAnchorEl(event.currentTarget);
    }
  };
  const handleHoverOff = () => {
    if (onHoverAction) {
      setAnchorEl(null);
    }
  };
  const handleClose = (event) => {
    if (anchorEl && anchorEl.contains(event.target)) {
      return;
    }
    setAnchorEl(null);
  };
  const handleCloseMenu = (param) => {
    setAnchorEl(null);
    if (props && props.onClick) {
      props.onClick(param);
    }
    if (param?.props?.onClick) {
      param.props.onClick();
    }
  };
  const {
    title,
    buttonText,
    buttonIcon,
    dropdownList,
    buttonProps,
    dropUp = false,
    dropdownHeader,
    caret = true,
    dropPlacement,
    rtlActive,
    noLiPadding,
    innerDropDown,
    onHoverAction,
    extraMarginTop,
    boxShadowStrong,
    withDivider = false,
    keepMounted = false,
    direction = "vertical",
  } = props;
  const caretStyles = [
    styles.caret,
    dropUp && !anchorEl && styles.caretDropup,
    Boolean(anchorEl) && !dropUp && styles.caretActive,
    Boolean(rtlActive) && styles.caretRTL,
  ];

  const dropdownItemStyles = [
    styles.dropdownItem,
    Boolean(noLiPadding) && styles.noLiPadding,
    Boolean(rtlActive) && styles.dropdownItemRTL,
  ];

  const dropdownManagerStyles = [
    Boolean(innerDropDown) && styles.innerManager,
    !innerDropDown && styles.manager,
  ];

  const dropdownBoxStyles = [
    styles.dropdown,
    Boolean(extraMarginTop) && styles.marginTop,
    Boolean(boxShadowStrong) && styles.boxShadowStrong,
  ];

  const menuListStyles = [
    styles.menuList,
    direction === "horizontal" && styles.menuHorizontal,
  ];

  const dropDownMenu =
    !props.children && dropdownList ? (
      <MenuList role="menu" sx={menuListStyles}>
        {dropdownHeader !== undefined ? (
          <MenuItem
            onClick={() => handleCloseMenu(dropdownHeader)}
            sx={styles.dropdownHeader}
          >
            {dropdownHeader}
          </MenuItem>
        ) : null}
        {dropdownList.map((prop, key) => {
          if (prop.props !== undefined && prop.props["data-ref"] === "multi") {
            return (
              <MenuItem
                key={`item-${props.key || key}`}
                sx={[
                  ...dropdownItemStyles,
                  { overflow: "visible", padding: 0 },
                ]}
              >
                {prop}
              </MenuItem>
            );
          }
          return (
            <MenuItem
              key={props.key || key}
              onClick={() => handleCloseMenu(prop)}
              sx={dropdownItemStyles}
            >
              {prop}
              {withDivider && key + 1 !== dropdownList.length && (
                <Divider sx={{ mt: 2 }} />
              )}
            </MenuItem>
          );
        })}
      </MenuList>
    ) : (
      <div />
    );

  return (
    <Box sx={dropdownManagerStyles} onMouseLeave={handleHoverOff}>
      {title && (
        <Box mb={1}>
          <Typography paragraph variant="subtitle2" sx={styles.title}>
            {title}
          </Typography>
        </Box>
      )}
      <Box sx={buttonText !== undefined ? {} : styles.target}>
        <Button
          aria-label="Notifications"
          aria-owns={anchorEl ? "menu-list" : undefined}
          aria-haspopup="true"
          {...buttonProps}
          onClick={handleClick}
          onMouseEnter={handleHoverOn}
        >
          <Box
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box display="flex" alignItems="center">
              {buttonIcon !== undefined ? buttonIcon : null}
              {buttonText !== undefined ? buttonText : null}
            </Box>
            {caret ? <Box component="b" sx={caretStyles} /> : null}
          </Box>
        </Button>
      </Box>
      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        keepMounted={keepMounted}
        disablePortal
        placement={dropPlacement}
        style={{ zIndex: 9 }}
        sx={{ "& .MuiPaper-root": { p: 0 } }}
        nonce={undefined}
        onResize={undefined}
        onResizeCapture={undefined}
      >
        {props.children
          ? props.children
          : () => (
              <Grow
                in={Boolean(anchorEl)}
                style={
                  dropUp
                    ? { transformOrigin: "0 100% 0" }
                    : { transformOrigin: "0 0 0" }
                }
              >
                <Paper sx={dropdownBoxStyles}>
                  {innerDropDown
                    ? dropDownMenu
                    : isValidElement(dropDownMenu) && (
                        <ClickAwayListener onClickAway={handleClose}>
                          {dropDownMenu}
                        </ClickAwayListener>
                      )}
                </Paper>
              </Grow>
            )}
      </Popper>
    </Box>
  );
};

export default CustomDropdown;
